export const AssessmentCategory = Object.freeze({
  MOBILITY: 'MOBILITY',
  STABILITY: 'STABILITY',
  ASSESSMENT_AND_PRACTICE: 'ASSESSMENT_AND_PRACTICE',
})

export const AssessmentLevel = Object.freeze({
  ALL_PLAYERS: 'ALL_PLAYERS',
  LEVEL_1: 'LEVEL_1',
  LEVEL_2: 'LEVEL_2',
  LEVEL_3: 'LEVEL_3',
  LEVEL_4: 'LEVEL_4',
  PERFORMANCE_LEVEL: 'PERFORMANCE_LEVEL',
})

export const AssessmentFrequency = Object.freeze({
  WEEKS_2: 'WEEKS_2',
  WEEKS_3: 'WEEKS_3',
})

export const AssessmentMeasurement = Object.freeze({
  EMPTY: 'EMPTY',
  QTY: 'QTY',
  CONVERSION: 'CONVERSION',
  INSIDE_3: 'INSIDE_3',
  INSIDE_6: 'INSIDE_6',
  INSIDE_9: 'INSIDE_9',
  INSIDE_12: 'INSIDE_12',
  INSIDE_15: 'INSIDE_15',
  INSIDE_20: 'INSIDE_20',
  INSIDE_25: 'INSIDE_25',
  INSIDE_30: 'INSIDE_30',
})

export const AssessmentPracticeType = Object.freeze({
  EMPTY: 'EMPTY',
  BLOCK: 'BLOCK',
  BROKEN: 'BROKEN',
  RANDOM: 'RANDOM',
  DIFFERENTIAL: 'DIFFERENTIAL',
  VARIABLE: 'VARIABLE',
  HIGH_PERFORMANCE: 'HIGH_PERFORMANCE',
})

export const DistanceUnit = Object.freeze({
  FEET: 'FEET',
  YARDS: 'YARDS',
})

export const ExerciseCategory = Object.freeze({
  FITNESS: 'fitness',
  PRACTICE: 'practice',
})

export const SessionCategory = Object.freeze({
  FITNESS: 'fitness',
  PLAY: 'play',
  PRACTICE: 'practice',
})

export const SessionType = Object.freeze({
  ANKLE: 'ankle',
  APPROACH: 'approach',
  CORE: 'core',
  DRIVING: 'driving',
  INLINE: 'inline',
  NECK: 'neck',
  PELVIC: 'pelvic',
  PLAY: 'play',
  PUTTING: 'putting',
  SHORT_GAME: 'shortGame',
  SHOULDER: 'shoulder',
  THORACIC: 'thoracic',
})

export const TipType = Object.freeze({
  NEGATIVE: 'negative',
  POSITIVE: 'positive',
})

export const UploadableFileTypes = Object.freeze({
  IMAGE: 'image',
  VIDEO: 'video',
})

export const USER_ROLES = Object.freeze({
  ADMIN: 'ADMIN',
  COACH: 'COACH',
  ATHLETE: 'ATHLETE',
})
